
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useMemo } from 'react';
import type { NextPage } from 'next';
import { withOnboardingPageWrapper } from '@/components/OnboardingPageWrapper/OnboardingPageWrapper';
import { DownloadAppsStepInfo, FocusAreaStepInfo } from '@/components/Steps';
import { getGrowthBookFeatures } from '@/context/Growthbook';
import { defaultFlowModel } from '@/features/default-flow/models/default-flow.model';
import { useAppSelector } from '@/hooks';
import { Step } from '@/shared/Step';
import { withAuthGuard } from '../guards/AuthGuard';
import { withFlowGuard } from '../guards/FlowGuard';
import { withOnboardingGuard } from '../guards/OnboardingGuard';
import { StepTypes } from '../types';
// Memoize steps to avoid recalculating on every render
const steps = defaultFlowModel.getSteps();
const getStep = <T extends StepTypes>(stepKey: keyof T | undefined) => {
    // Check if the stepKey is DownloadAppsStepInfo.id and if it's not already in the list
    if (stepKey === DownloadAppsStepInfo.id && !steps.some(step => step.id === DownloadAppsStepInfo.id)) {
        steps.push(DownloadAppsStepInfo);
    }
    // Return the found step or fall back to FocusAreaStepInfo
    return steps.find(step => step.id === stepKey) || FocusAreaStepInfo;
};
const HomePageContent: NextPage = () => {
    const stepId = useAppSelector(state => state.onboarding.currentStep.id);
    const currentStep = useMemo(() => getStep(stepId), [stepId]);
    const { Component: StepContent, ...restStepProps } = currentStep;
    return (<Step {...restStepProps}>
      <StepContent flow={defaultFlowModel}/>
    </Step>);
};
const getStaticProps = getGrowthBookFeatures;
const WithWrapper = withOnboardingPageWrapper(HomePageContent, {
    flow: defaultFlowModel,
    getStep,
});
const WithOnboardingGuard = withOnboardingGuard(WithWrapper);
const WithFlowGuard = withFlowGuard(WithOnboardingGuard);
const HomePage = withAuthGuard(WithFlowGuard);
export default HomePage;

    async function __Next_Translate__getStaticProps__19586af569a__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19586af569a__ as getStaticProps }
  